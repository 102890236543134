<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <div class="mb-6">
        <h2 class="font-semibold text-blue-900 text-2xl">
          Wallet Transaction Log
        </h2>
      </div>
      <div class="flex flex-row mt-5">
        <div class="float-right">
          <input
            type="text"
            class="inline-block px-2 ring-2 outline-none ring-blue-500 rounded-sm ml-2 w-64 text-sm py-2"
            name=""
            placeholder="Search business name or email address"
            id=""
            v-model="search"
          />
        </div>
        <button
          @click="searchFund"
          class="
                    float-right
                    uppercase
                    shadow
                    ml-3
                    hover:bg-blue-600
                    bg-blue-900
                    focus:shadow-outline
                    focus:outline-none
                    text-white text-xs
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
        >
          Search
        </button>
        <button
          @click="clearSearch"
          class="
                    float-right
                    uppercase
                    shadow
                    ml-3
                    hover:bg-red-600
                    bg-red-900
                    focus:shadow-outline
                    focus:outline-none
                    text-white text-xs
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
        >
          Clear
        </button>
      </div>
      <div class="bg-blue-100 p-5 my-5">
        <p>
          Please note that this table contains only accounts with a wallet and
          their transactions.
        </p>
      </div>
      <div class="flex flex-row mt-5" style="max-height:600px;overflow:auto;">
        <table class="shadow-lg bg-white w-full border-collapse">
          <thead>
            <tr>
              <th
                class="text-l p-3 text-left"
                colspan="5"
                style="background: #DEE6F2;"
              >
                Merchant Information
              </th>
            </tr>
            <tr>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Business ID
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Business Name
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Email Address
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Phone Number
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Business Address
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(account, i) in filterDocuments"
              :key="i"
              @click="
                $router.push({
                  name: 'ViewWalletTransactionLog',
                  params: {
                    id: account.business_id,
                  },
                })
              "
              class="cursor-pointer"
            >
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.business_id }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.business_name }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.email }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.phone_number }}
              </td>
              <td class="border px-8 py-3 text-xs">
                {{ account.business_address }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-3">
        <button
          @click="prevPage(currentPage)"
          :disabled="isDisabled"
          class="
                    
                    uppercase
                    shadow
                    ml-3
                    hover:bg-blue-600
                    bg-blue-900
                    focus:shadow-outline
                    focus:outline-none
                    text-white text-xs
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
        >
          &laquo; Prev
        </button>
        <button
          @click="nextPage(currentPage)"
          class="
                    float-right
                    uppercase
                    shadow
                    ml-3
                    hover:bg-blue-600
                    bg-blue-900
                    focus:shadow-outline
                    focus:outline-none
                    text-white text-xs
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
        >
          Next &raquo;
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
import { json2excel } from "js2excel";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      moment,
      isLoading: false,
      currentPage: 1,
      isDisabled: false,
      filterBy: [],
      search: "",
      walletFilter: [],
      downloadLink: null,
      loading: false,
      viewModal: false,
      payload: [],
      fundsData: [],
    };
  },
  created() {
    this.fetchRecords();
  },
  computed: {
    ...mapGetters(["GET_WALLET_TRANSACTION"]),
    filterDocuments() {
      //let y = this.GET_DOCUMENTS.data.filter((b) => b.status === x);
      // return this.GET_WALLET_TRANSACTION.data.items.filter(
      //   (account) =>
      //     account.email.toLowerCase().includes(this.search.toLowerCase()) ||
      //     account.business_id
      //       .toLowerCase()
      //       .includes(this.search.toLowerCase()) ||
      //     account.phone_number
      //       .toLowerCase()
      //       .includes(this.search.toLowerCase()) ||
      //     account.business_name
      //       .toLowerCase()
      //       .includes(this.search.toLowerCase())
      // );
      return this.GET_WALLET_TRANSACTION.data.items;
    },
  },
  methods: {
    downloadCSV() {
      let data = this.fundsData.map((data) => {
        return {
          "Business Id": data.BusinessId || "N/A",
          "Business Name": data.BusinessName || "N/A",
          "Account Number": data.AccountNumber || "N/A",
          "Recipient Name": data.RecipientName || "N/A",
          "Wallet ID": data.WalletId || "N/A",
          "Session ID": data.SessionId || "N/A",
          "Email Address": data.Email || "N/A",
          "Transaction Date":
            moment(data.DateTransferred).format("DD MMM YYYY - h:mm:ss a") ||
            "N/A",
          "Amount ": data.Amount || "N/A",
          "Source Bank": data.SourceBank || "N/A",
          "Destination Bank": data.DestinationBank || "N/A",
          "Payment Reference": data.TransactionReference || "N/A",
          "Transaction Type": data.TransactionType || "N/A",
          "Reversal Date":
            moment(data.ReversalDate).format("DD MMM YYYY - h:mm:ss a") ||
            "N/A",
          Narration: data.Narration || "N/A",
          "Transaction Status": data.TransactionStatus,
        };
      });
      json2excel({
        data,
        name: "Funds Transfer",
        formateDate: this.moment().format("DD MMM YYYY - h:mm:ss a"),
      });
    },
    async fetchRecords() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("FETCH_WALLET_TRANSACTIONS");
        if (res.status == true) {
          this.fundsData = res.data;
          // console.log(res.data);
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {}

      this.isLoading = false;
    },

    async searchFund() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch(
          "SEARCH_SINGLE_WALLET_TRANSACTIONS",
          {
            search: this.search,
          }
        );
        this.isLoading = false;
        if (res.status === true) {
          // this.fundsData = res.data;
          // console.log(res.data);
        } else {
          this.isLoading = false;
          this.GET_WALLET_TRANSACTION.data = [];
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {}

      this.isLoading = false;
    },

    async clearSearch() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("FETCH_WALLET_TRANSACTIONS");
        if (res.status == true) {
          this.isLoading = false;
          // this.fundsData = res.data;
          // console.log(res.data);
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {}
    },
    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        "FETCH_PAGED_WALLET_TRANSACTIONS",
        currPage - 1
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage -= 1;
      }
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        "FETCH_PAGED_WALLET_TRANSACTIONS",
        currPage + 1
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage += 1;
      }
    },
  },
};
</script>
<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
